import { useCallback, useState } from 'react';
import { Button, ButtonProps, Popconfirm, PopconfirmProps } from 'antd';

export interface StatusButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: (...params: Parameters<Required<ButtonProps>['onClick']>) => Promise<any>;
  popConfirmProps?: PopconfirmProps;
}

function StatusButton(props: StatusButtonProps) {
  const { onClick, popConfirmProps, ...otherProps } = props;

  const [loading, setLoading] = useState(false);

  const handler = useCallback(
    (e: any) => {
      if (!onClick) {
        return;
      }

      setLoading(true);

      onClick(e).finally(() => {
        setLoading(false);
      });
    },
    [onClick],
  );

  if (!popConfirmProps) {
    return <Button loading={loading} onClick={handler} {...otherProps} />;
  }

  return (
    <Popconfirm {...popConfirmProps} onConfirm={handler}>
      <Button loading={loading} {...otherProps} />
    </Popconfirm>
  );
}

export default StatusButton;
