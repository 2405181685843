import { useState, useMemo, useRef } from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';

export interface Config {
  defaultPending?: boolean;
  defaultError?: Error | null;
  namespace?: string;
  handleError?(error: Error, namespace?: string): void;
}

function simpleHandleError(error: Error, namespace?: string) {
  if (namespace) {
    // eslint-disable-next-line no-console
    console.warn('%s error:', namespace);
  }

  // eslint-disable-next-line no-console
  console.error(error);
}

function useStatus(config: Config = {}) {
  const {
    defaultPending = false,
    defaultError = null,
    namespace,
    handleError = simpleHandleError,
  } = config;

  const [pending, setPending] = useState(defaultPending);
  const [error, setError] = useState(defaultError);
  const handleRef = useRef({
    namespace,
    handleError,
  });

  const done = useMemo(() => {
    function done() {
      setPending(false);
    }

    function pending() {
      batchedUpdates(() => {
        setPending(true);
        setError(null);
      });
    }

    function error(error: Error) {
      batchedUpdates(() => {
        setPending(false);
        setError(error);

        const {
          current: { namespace, handleError },
        } = handleRef;

        if (handleError) {
          handleError(error, namespace);
        }
      });
    }

    done.pending = pending;
    done.error = error;

    return done;
  }, []);

  return {
    pending,
    error,
    done,
  };
}

export default useStatus;
