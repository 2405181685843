/**
 * 等待指定时间
 * @param time 等待时间 (毫秒)
 */
function wait(time: number = 0) {
  return new Promise<number>((resolve) => {
    if (time > 0) {
      const start = Date.now();

      setTimeout(() => {
        const end = Date.now();

        resolve(end - start);
      }, time);
    } else {
      resolve(0);
    }
  });
}

export default wait;
