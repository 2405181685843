import { useCallback, useRef, useState } from 'react';
import useStatus from './useStatus';

export interface UseCrudProps<T, C, R, RL, U, D, CR, UR, DR> {
  create?: (c: C) => Promise<CR>;
  read?: (f: R) => Promise<T>;
  readList?: (rl: RL) => Promise<T[]>;
  update?: (u: U) => Promise<UR>;
  del?: (d: D) => Promise<DR>;
}

function useCrud<T, C, R, RL, U, D, CR, UR, DR>(
  props: UseCrudProps<T, C, R, RL, U, D, CR, UR, DR>,
) {
  const { create: createProp, read, readList, update: updateProp, del: delProp } = props;

  const [item, setItem] = useState<T>();

  const [list, setList] = useState<T[]>();

  const { pending, error, done } = useStatus();

  const fetchRef = useRef(0);

  const fetch = useCallback(
    (r?: R) => {
      if (!read) {
        throw new Error(
          'fetch() should be used after you provide a read function prop for useCrud hook',
        );
      }

      done.pending();

      fetchRef.current += 1;
      const { current } = fetchRef;

      return read(r as R)
        .then((res) => {
          if (current !== fetchRef.current) {
            return res;
          }

          setItem(res);
          done();

          return res;
        })
        .catch((err) => {
          done.error(err);

          return Promise.reject(err);
        });
    },
    [read, done],
  );

  const fetchListRef = useRef(0);

  const fetchList = useCallback(
    (rl?: RL) => {
      if (!readList) {
        throw new Error(
          'fetchList() should be used after you provide a readList function prop for useCrud hook',
        );
      }

      done.pending();

      fetchListRef.current += 1;
      const { current } = fetchListRef;

      return readList(rl as RL)
        .then((res) => {
          if (current !== fetchListRef.current) {
            return res;
          }

          setList(res);
          done();

          return res;
        })
        .catch((err) => {
          done.error(err);

          return Promise.reject(err);
        });
    },
    [readList, done],
  );

  const create = useCallback(
    (c: C, refreshList?: boolean, refreshListParameters?: RL) => {
      if (!createProp) {
        throw new Error(
          'create() should be used after you provide a create function prop for useCrud hook',
        );
      }

      return createProp(c).then((res) => {
        if (!refreshList) {
          return res;
        }

        return fetchList(refreshListParameters as RL).then(() => {
          return res;
        });
      });
    },
    [createProp, fetchList],
  );

  const update = useCallback(
    (
      u: U,
      refresh?: boolean,
      refreshList?: boolean,
      refreshParameters?: R,
      refreshListParameters?: RL,
    ) => {
      if (!updateProp) {
        throw new Error(
          'update() should be used after you provide a update function prop for useCrud hook',
        );
      }

      return updateProp(u).then((res) => {
        if (refresh) {
          return fetch(refreshParameters as R).then(() => {
            return res;
          });
        }

        if (refreshList) {
          return fetchList(refreshListParameters as RL).then(() => {
            return res;
          });
        }

        return res;
      });
    },
    [updateProp, fetch, fetchList],
  );

  const del = useCallback(
    (d: D, refreshList?: boolean, refreshListParameters?: RL) => {
      if (!delProp) {
        throw new Error(
          'del() should be used after you provide a del function prop for useCrud hook',
        );
      }

      return delProp(d).then((res) => {
        if (!refreshList) {
          return res;
        }

        return fetchList(refreshListParameters as RL).then(() => {
          return res;
        });
      });
    },
    [delProp, fetchList],
  );

  return {
    item,
    setItem,
    list,
    setList,
    loading: pending,
    done,
    error,
    fetch,
    fetchList,
    create,
    update,
    del,
  };
}

export default useCrud;
